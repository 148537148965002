import { KeyboardEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MenuHelper from '../utils/MenuHelper';

export const Menu = () => {
	const { t } = useTranslation();

	const [mobileMenuOpenState, setMobileMenuOpenState] = useState(false);

	function toggleMobileMenuKeyDown(event: KeyboardEvent<HTMLElement>) {
		if (event.code.toLowerCase() !== 'tab') {
			toggleMobileMenuVisibility();
		}
	}

	function toggleMobileMenuVisibility() {
		setMobileMenuOpenState(!mobileMenuOpenState);
	}

	return (
		<nav>
			<div
				className='mobileMenuToggleButton'
				onClick={toggleMobileMenuVisibility}
				onKeyDown={toggleMobileMenuKeyDown} 
				role='button'
				tabIndex={0}
			>
				≡
			</div>
			{mobileMenuOpenState && <div className='mobileMenuContent'>
				{MenuHelper.mainMenuConfiguration.map((menuConfigItem, index) =>
					menuConfigItem.hasMenuItem && MenuHelper.generateLinkJsx(menuConfigItem, toggleMobileMenuVisibility)
				)}
			</div>}
		</nav>
	);
}