import React from 'react';
//import { Link, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LanguageSwitcher } from './LanguageSwitcher';
import { Menu } from './Menu';


export const Footer = () => {
	const { t } = useTranslation();
	return (
		<div className='appFooter'>
			<div className='appFooterLeft'>
				<Menu/>
			</div>
			<div className='appFooterCenter'>
				<a href='https://creativecommons.org/licenses/by-nc-sa/2.0/' target='_blank' rel='noreferrer'>
					<img className='ccLicenseBanner' src='/images/cc-by-nc-sa.svg' alt='Creative Commons BY-NC-SA 2.0 License' />
				</a>
			</div>
			<div className='appFooterRight'>
				{/* full menu here instead of languages */}
				<LanguageSwitcher/>
			</div>
		</div>
	);
}