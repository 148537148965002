import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Imprint = () => {
	const { t } = useTranslation();
	return (
		<div className='textContentContainer'>
			<h1>Imprint, Disclaimer, Cookie Policy &amp; Data Protection</h1>
			<h2 className='textLeft'>Imprint</h2>
			<div className='textLeft'>
				<p>This Website and the information on it are provided by a small group of people living in and around Ingelheim.</p>
				<p>In case you find any problematic content feel free to contact us via the following email:</p>
				<p>
					Manuel Feller<br/>
					Postfach ?????<br/>
					????? Ingelheim am Rhein<br/>
					website@ingelheim.mobi
				</p>
				<p>
					As we are not a business and have regular day-jobs responds can take up to 48 hours. We try our best to answer faster whenever possible.
				</p>
			</div>
			<h2 className='textLeft'>Disclaimer</h2>
			<div className='textLeft'>
				<ul>
					<li>All information and ratings provided here are intended to help but a subjective view of the authors.</li>
					<li>Information and links may be outdated, even if we try to keep it up to date as good as possible. You are welcome to let us know there is something that needs updating.</li>
					<li>This page provides no legal advice!</li>
				</ul>
			</div>
			<h2 className='textLeft'>Cookie Policy</h2>
			<div className='textLeft'>
				<p>This page does not use any cookies. We only save your language preference in the browsers local storage (variable name 'i18nextLng') and never transmit this information to anywhere. To delete this information you need to clear your browsers local storage for this website.</p>
			</div>
			<h2 className='textLeft'>Data Protection</h2>
			<div className='textLeft'>
				<p>We do not provide any forms to enter any data and also do not offer any services that require you to contact us.</p>
				<p>If you contact us via the above Email the Information is shared with the webmaster who may then also forward it to the according team member for further processing.</p>
				<p>
					We delete conversations that we consider resolved after a few weeks; if you contact us you can also let us know after what time we should delete your submitted information.<br/>
					Please keep in mind that we can not delete any emails before we actually received them on our local device(s).
				</p>
			</div>
		</div>	
	);
}
