import { Link } from 'react-router-dom';
import i18next from 'i18next';
import { Start } from '../pages/Start';
import { Map } from '../pages/Map';
import { Imprint } from '../pages/Imprint';
import { Knowledge } from '../pages/Knowledge';

export default class MenuHelper {

  public static mainMenuConfiguration = [
    {route: '/', translationKey: 'navLink.start', hasMenuItem: true, pageElement: Start},
    {route: '/map', translationKey: 'navLink.map', hasMenuItem: true, pageElement: Map},
    {route: '/knowledge', translationKey: 'navLink.knowledge', hasMenuItem: true, pageElement: Knowledge},
    {route: '/imprint', translationKey: 'navLink.imprint', hasMenuItem: true, pageElement: Imprint},
    {route: '/knowledge/:articleId', translationKey: '', hasMenuItem: false, pageElement: Knowledge},
    {route: '/map/:collectionId', translationKey: '', hasMenuItem: false, pageElement: Map},
  ];

  public static generateLinkJsx(menuConfigItem: any, onClickFunction: any) {
    const linkText = i18next.t(menuConfigItem.translationKey);
    return (
      <Link
        to={menuConfigItem.route}
        className='mobileMenuLink'
        onClick={onClickFunction}
      >
        {linkText}
      </Link>
    );
  }
}