//import { useTranslation } from 'react-i18next';
import i18n from '../utils/i18n';

const availableLanguages: any = {
	de: { nativeName: 'Deutsch', flagEmoji: '🇩🇪' },
	es: { nativeName: 'Español', flagEmoji: '🇪🇸' },
	en: { nativeName: 'English', flagEmoji: '🇺🇸' },
	zh: { nativeName: '中文', flagEmoji: '🇨🇳' },
};

export const LanguageSwitcher = () => {
	
	return (
		<div>
      {Object.keys(availableLanguages).map((lng) => (
        <button key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
          {availableLanguages[lng].flagEmoji} {availableLanguages[lng].nativeName}
        </button>
      ))}
    </div>
	);
}