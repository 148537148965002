import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Header = () => {
	const { t } = useTranslation();
	return (
		<div className='appHeader'>
			<p>{t('app.header')}</p>
		</div>
	);
}