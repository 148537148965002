import './App.css';
import './utils/i18n';
import { BrowserRouter as ReactRouter } from 'react-router-dom';
import { Header } from './components/Header';
import { AppRoutes } from './AppRoutes';
import { Footer } from './components/Footer';

function App() {
  const addSafariClassIfNeeded = () => {
    if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
      return('appContainer heightFixForSafari');
    } else {
      return('appContainer');
    }
  }
  
  return (
    <div className={addSafariClassIfNeeded()}>
      <ReactRouter>
        <Header/>
        <div className='mainContentContainer'>
          <AppRoutes/>
        </div>
        <Footer/>
      </ReactRouter>
    </div>
  );
}

export default App;
