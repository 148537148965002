import React from 'react';
import { createRoot } from "react-dom/client";
import './index.css';
import App from './App';
import 'leaflet/dist/leaflet.css';
import 'leaflet/dist/leaflet';
//import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById("root") as Element)
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
