import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Knowledge = () => {
	const { t } = useTranslation();
	return (
		<div className='textContentContainer'>
			<h1>Knowledge</h1>
			<div>
				<p>{t('startPage.p1')}</p>
			</div>
		</div>	
	);
}
