import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MenuHelper from './utils/MenuHelper';

export const AppRoutes = () => {
	return (
		<Routes>
			{MenuHelper.mainMenuConfiguration.map((menuConfigItem, index) => 
				<Route
					path={menuConfigItem.route}
					element={<menuConfigItem.pageElement />}
				/>
			)}
		</Routes>
	);
};