import React from 'react';
import { useParams } from 'react-router-dom';
import locationCollections from '../data/location_collections.json';
import locations from '../data/locations.json';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { Icon } from "leaflet";
import { useTranslation } from 'react-i18next';

export const Map = () => {

	const { collectionId } = useParams();
	const { t, i18n } = useTranslation();
	const locationList = [];

	const markerIcon = new Icon({
		iconUrl: "/images/locations.svg",
		iconSize: [40, 40]
	});

	let validatedCollection = undefined;
	if (collectionId !== undefined) {
		// check if there is a collection available with that name (it is an numeric id)
		for (let collectionDetails of locationCollections) {
			if (collectionId === collectionDetails.id.toString()) {
				validatedCollection = collectionDetails;
			}
		}
	}
	// if no collection was chosen then take the first one or the one with the lowest sort index
	if (validatedCollection === undefined) {
		// ToDo: check for sorting first
		validatedCollection = locationCollections[0];
	}
	// filter locations according to collection
	for (let location of locations) {
		if(validatedCollection?.locationsInCollection.indexOf(location.id) !== -1)
		{
			locationList.push(location);
		}
	}

	function getRatingInfo(rating: number, details: any) {
		//★✩
		let output = '';
		for (let count: number = 0; count < 5; count++) {
			if (rating > count) {
				output += '★';
			} else {
				output += '✩';
			}
		}
		if (details !== null) {
			output += ` (${details})`
		}
		return output;
	}

	function generateRatingLine(title: string, location: any, propertyName: string) {
		// check if the requested item is set to any value
		if (location[propertyName] !== undefined && location[propertyName] !== null) {
			const rating = getRatingInfo(
				(location[propertyName] as number), 
				getTranslatedProperty(location, propertyName.concat('Note'))
			);
			return (
				<>
					{title.concat(': ', rating)}<br/>
				</>
			);
		}
	}

	function getAddressBlock(location: any) {
		if (location.streetAndNumber !== null || location.zipCodeAndCity !== null) {
			if (location.streetAndNumber !== null && location.zipCodeAndCity === null) {
				return (
					<>
						{location.streetAndNumber}<br/>&nbsp;<br/>
					</>
				);
			}
			else if (location.streetAndNumber !== null && location.zipCodeAndCity !== null) {
				return (
					<>
						{location.streetAndNumber}<br/>
						{location.zipCodeAndCity}<br/>&nbsp;<br/>
					</>
				);
			}
			else if (location.streetAndNumber === null && location.zipCodeAndCity !== null) {
				return (
					<>
						{location.zipCodeAndCity}<br/>&nbsp;<br/>
					</>
				);
			}
		}
	}

	function createTelLinkFromPhoneNumber(phoneNumber: string) {
		return 'tel:'.concat(phoneNumber.split(" ").join("").split("-").join(""));
	}

	function getContactBlock(location: any) {
		if (location.websiteUrl !== null || location.phoneNumber !== null) {
			if (location.websiteUrl !== null && location.phoneNumber === null) {
				return (
					<>
						🌐: <a href={location.websiteUrl} target="_blank" rel="noreferrer">{location.websiteUrl}</a><br/>&nbsp;<br/>
					</>
				);
			}
			else if (location.websiteUrl !== null && location.phoneNumber !== null) {
				return (
					<>
						🌐: <a href={location.websiteUrl} target="_blank" rel="noreferrer">{location.websiteUrl}</a><br/>&nbsp;<br/>
						📞: <a href={createTelLinkFromPhoneNumber(location.phoneNumber)}>{location.phoneNumber}</a><br/>&nbsp;<br/>
					</>
				);
			}
			else if (location.websiteUrl === null && location.phoneNumber !== null) {
				return (
					<>
						📞: <a href={createTelLinkFromPhoneNumber(location.phoneNumber)}>{location.phoneNumber}</a><br/>&nbsp;<br/>
					</>
				);
			}
		}
	}

	function getTranslatedProperty(location: any, propertyName: string) {
		let fallbackValue = null;
		for (let details of location.translatedDetails) {
			if (details.languageCode === i18n.language) {
				return details[propertyName];
			}
			if (details.languageCode === (i18n.options.fallbackLng as any)[0]) {
				fallbackValue = details[propertyName];
			}

		}
		console.debug(`Missing data for language '${i18n.language}', using fallback language`);
		return fallbackValue;
	}


	return (
		<MapContainer center={[49.9755, 8.0443]} zoom={14} scrollWheelZoom={true}>
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>

			{locationList.map((locationDetails, index) =>
				<Marker key={index} 
					position={[
						locationDetails.coordinates.coordinates[1],
						locationDetails.coordinates.coordinates[0]
					]}
					icon={markerIcon}>
					<Popup>
						<strong>{locationDetails.name}</strong><br/>
						{getTranslatedProperty(locationDetails, 'description')}<br/>&nbsp;<br/>
						{getAddressBlock(locationDetails)}
						{getContactBlock(locationDetails)}
						{generateRatingLine(t('ratingName.food'), locationDetails, 'foodQuality')}
						{generateRatingLine(t('ratingName.location'), locationDetails, 'locationRating')}
						{generateRatingLine(t('ratingName.service'), locationDetails, 'serviceRating')}
					</Popup>
				</Marker>
			)}

		</MapContainer>
	);
}